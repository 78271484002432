<template>
	<div v-if="showForgetPassword">
		<a href="#" style="text-decoration: none" @keyup.enter="dialogs.forgetPassword.show = true" @click="dialogs.forgetPassword.show = true">{{
			$t('forgetpassword.link')
		}}</a>
		<pui-modal-dialog
			v-if="dialogs.forgetPassword.show"
			:cancelText="$t('form.cancel')"
			:titleText="dialogs.forgetPassword.titleText"
			:messageText="dialogs.forgetPassword.messageText"
			:okText="dialogs.forgetPassword.okText"
			:disableOk="dialogs.forgetPassword.disableOk"
			dialogName="forgetPasswordDialog"
			ref="forgetPasswordDialog"
		>
			<template v-slot:message>
				<pui-text-field v-model="dialogs.forgetPassword.usremail" noeditable updateValueRealtime maxlength="100"></pui-text-field>
			</template>
		</pui-modal-dialog>
	</div>
</template>

<script>
export default {
	name: 'PuiUserForgetPassword',
	data() {
		return {
			requestResetPasswordUrl: '/puiuser/requestResetPassword',
			dialogs: {
				forgetPassword: {
					show: false,
					titleText: this.$t('forgetpassword.title'),
					messageText: this.$t('forgetpassword.message'),
					okText: this.$t('forgetpassword.ok'),
					disableOk: false,
					usremail: null
				}
			}
		};
	},
	mounted() {
		this.subscribeToForgetPasswordDialogEvent();
	},
	beforeDestroy() {
		this.unsubscribeToForgetPasswordDialogEvent();
	},
	computed: {
		showForgetPassword() {
			return this.$store.state.login.forgetPassword;
		}
	},
	methods: {
		subscribeToForgetPasswordDialogEvent() {
			this.$puiEvents.$on('pui-modalDialog-forgetPasswordDialog-ok', () => {
				this.onResetPressSendMail();
			});
			this.$puiEvents.$on('pui-modalDialog-forgetPasswordDialog-cancel', () => {
				this.dialogs.forgetPassword.show = false;
			});
		},
		unsubscribeToForgetPasswordDialogEvent() {
			this.$puiEvents.$off('pui-modalDialog-forgetPasswordDialog-ok');
			this.$puiEvents.$off('pui-modalDialog-forgetPasswordDialog-cancel');
		},
		async onResetPressSendMail() {
			const usrEmail = this.dialogs.forgetPassword.usremail;
			if (!usrEmail || usrEmail === '') {
				return this.$puiNotify.error(this.$t('forgetpassword.usremail.label'));
			}

			try {
				await this.$puiRequests.getRequest(this.requestResetPasswordUrl, { usrEmail });

				this.dialogs.forgetPassword.show = false;
				this.$puiNotify.success(this.$t('forgetpassword.usremail.response'));
			} catch (error) {
				this.$store.dispatch('puiRequestShowServerError', { error });
			}
		}
	}
};
</script>
