<template>
	<v-app id="pui9app">
		<pui-cookies-bar v-if="!(showResetPassword && !isLdapActive)" />
		<pui-user-reset-password v-if="showResetPassword && !isLdapActive" />
		<operations-login-layout-mobile v-else-if="isMobile">
			<template slot="puiLoginEndActions">
				<pui-development-environment class="pui-development-environment" />
				<pui-user-forget-password v-if="!isLdapActive" class="pui-user-forget-password" />
			</template>
			<template slot="puiLoginAppActions">
				<component v-if="appLoginActions" :is="appLoginActions"></component>
			</template>
		</operations-login-layout-mobile>
		<operations-login-layout-desktop v-else>
			<template slot="puiLoginEndActions">
				<div class="d-flex flex-column align-start pl-15 pr-15 pt-0 pb-0">
					<operations-user-forget-password v-if="!isLdapActive" class="operations-user-forget-password" />
					<pui-development-environment class="operations-development-environment" />
				</div>
			</template>
			<template slot="puiLoginAppActions">
				<component v-if="appLoginActions" :is="appLoginActions"></component>
			</template>
			<template slot="puiLoginDisplayArea">
				<component v-if="appLoginDisplayArea" :is="appLoginDisplayArea"></component>
			</template>
		</operations-login-layout-desktop>
	</v-app>
</template>

<script>
import PuiCookiesBar from '@Pui9Base/src/components/PuiCookiesBar.vue';
import PuiUserResetPassword from '@Pui9Base/src/components/PuiUserResetPassword.vue';
import PuiDevelopmentEnvironment from '@Pui9Base/src/components/PuiDevelopmentEnvironment.vue';

import OperationsUserForgetPassword from './OperationsUserForgetPassword.vue';
import OperationsLoginLayoutDesktop from './OperationsLoginLayoutDesktop.vue';
import OperationsLoginLayoutMobile from './OperationsLoginLayoutMobile.vue';

export default {
	name: 'OperationsIntroLayout',
	components: {
		PuiCookiesBar,
		PuiUserResetPassword,
		PuiDevelopmentEnvironment,
		OperationsUserForgetPassword,
		OperationsLoginLayoutDesktop,
		OperationsLoginLayoutMobile
	},
	data() {
		return {
			isMobile: this.$store.getters.isMobile,
			appLoginDisplayArea: null,
			appLoginActions: null
		};
	},
	computed: {
		showResetPassword() {
			return this.$store.state.login.forgetPassword && window.location.href.includes('resetPassword');
		},
		isLdapActive() {
			return this.$store.getters.isLdapActive;
		}
	},
	created() {
		this.$store.dispatch('puiIsLdapActive', {});
		var recover = this.puiLoginRecoverSession();
		if (recover) {
			if (this.$store.getters.getToken && (!this.$store.state.session.use2fa || this.$store.state.login.validated2fa)) {
				if (!this.$route.query.redirect) {
					this.$store.dispatch('puiLoginGoToDefaultLayoutPage', { router: this.$router, route: this.$route });
				} else {
					this.$store.dispatch('puiLoginRedirectAfterLogin', { router: this.$router, route: this.$route });
				}
			}
		} else if (this.$route.query.redirect !== '/resetPassword') {
			this.$store.dispatch('puiLoginRedirectAfterLogin', { router: this.$router, route: this.$route });
		}

		if (this.$store.state.global.appLoginDisplayArea) {
			this.appLoginDisplayArea = 'applogindisplayarea';
		}
		if (this.$store.state.global.appLoginActions) {
			this.appLoginActions = 'apploginactions';
		}
	},
	methods: {
		puiLoginRecoverSession() {
			const infoKey = this.$store.state.session.keepSessionAliveInfoKey;
			var alive = window.localStorage.getItem(infoKey);
			if (alive) {
				var savedInfoStr = window.localStorage.getItem(infoKey);
				if (savedInfoStr) {
					const savedInfo = JSON.parse(savedInfoStr);
					this.setLoginInfo(savedInfo);
					this.$puiEvents.$emit('onPuiLoginSignin', savedInfo);
					return true;
				} else {
					return false;
				}
			}
			return false;
		},
		setLoginInfo(loginInfo) {
			//first commit the login info, the language will be set applying some logic
			this.$store.commit('puiLoginSetInfo', loginInfo);
			//then we use the language saved, not the recieved
			const lang = this.$store.getters.getUserLanguage;
			this.changeAppLang(lang);
			this.$store.commit('setHttpRequestHeaders', { authorization: loginInfo.jwt, acceptLanguage: lang });
		},
		changeAppLang(lang) {
			this.$puiI18n.locale = lang;
			window.localStorage.setItem('pui9_lang', lang);
			this.$store.commit('setUserLanguage', lang);
			this.$store.commit('setHttpRequestHeaders', { acceptLanguage: lang });
			this.$puiEvents.$emit('onPuiChangedLang', lang);
		}
	}
};
</script>

<style>
.operations-user-forget-password {
	width: 200px;
	height: 32px;
	flex-grow: 0;
	margin: 10px 100px 10px 0;
	font-family: Montserrat;
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.18;
	letter-spacing: normal;
	text-align: left;
	color: #000 !important;
	text-decoration: none;
}
.operations-development-environment {
	margin: auto;
	height: 32px;
	flex-grow: 0;
	margin: 10px 0px;
	font-family: Montserrat;
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.18;
	letter-spacing: normal;
	text-align: left;
	color: #000 !important;
	padding: 0px !important;
	align-content: flex-start;
}
</style>
