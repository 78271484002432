<template>
	<div>
		<operations-intro-layout />
		<!-- Modal for selecting user port -->
		<pui-modal-dialog-form
			:overflow="false"
			:titleText="$t('operationslogin.portauthorityselection')"
			:modelName="dialogModelName"
			:dialogName="dialogNameSelectLoginPort"
			v-model="modalData"
			:onOk="choosePort"
			:onCancel="closeDialog"
		>
			<template slot="message">
				<v-layout wrap>
					<v-flex xs12>
						<pui-select
							id="login-select-port"
							attach="login-select-port"
							v-model="modalData.portAuthority"
							:items="modalData.portAuthorities"
							itemText="name"
							itemValue="id"
							toplabel
							label="$nbsp;"
							required
							noeditable
						></pui-select>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
import { setUserId } from './../../plugins/matomo';
import OperationsIntroLayout from './OperationsIntroLayout.vue';

export default {
	components: { OperationsIntroLayout },
	data() {
		return {
			dialogNameSelectLoginPort: 'selectLoginPort',
			dialogModelName: 'login',
			modalData: { portAuthorities: [], portAuthority: { id: null } }
		};
	},
	created() {
		this.$store.commit('setAfterLoginFunction', this.afterLogin);
		this.cleanStorage();
	},
	methods: {
		afterLogin(loginInfo, keepSessionAlive) {
			this.$store.commit('setAfterLogoutFunction', this.cleanStorage);
			setUserId(loginInfo.usr);
			if (loginInfo.properties.PORT_AUTHORITIES && loginInfo.properties.PORT_AUTHORITIES.length > 1) {
				let portAuthorities = [];
				loginInfo.properties.PORT_AUTHORITIES.forEach((pa) =>
					portAuthorities.push({
						id: pa.id,
						name: pa.name
					})
				);

				portAuthorities = portAuthorities.reduce((accumulator, current) => {
					if (!accumulator.find((item) => item.id === current.id)) {
						accumulator.push(current);
					}
					return accumulator;
				}, []);
				const data = { loginInfo, keepSessionAlive, portAuthorities };
				this.$puiEvents.$emit(`pui-modalDialogForm-${this.dialogNameSelectLoginPort}-${this.dialogModelName}-show`, data);
			} else {
				this.modalData.portAuthority.id =
					loginInfo.properties.PORT_AUTHORITIES &&
					loginInfo.properties.PORT_AUTHORITIES.length > 0 &&
					loginInfo.properties.PORT_AUTHORITIES[0].id;
				/*-this.modalData.port.portlocode =
					loginInfo.properties.PORTS && loginInfo.properties.PORTS.length > 0 && loginInfo.properties.PORTS[0].portlocode;
				this.modalData.port.token =
					loginInfo.properties.PORTS && loginInfo.properties.PORTS.length > 0 && loginInfo.properties.PORTS[0].token;*/
				this.setSessionPortAutho(loginInfo, keepSessionAlive);
				console.log();
			}
		},
		choosePort(modalData) {
			this.setSessionPortAutho(modalData.loginInfo, modalData.keepSessionAlive);
			return Promise.resolve(true);
		},
		setSessionPortAutho(loginInfo, keepSessionAlive) {
			window.localStorage.setItem('workingPortAuthorityId', this.modalData.portAuthority.id);
			const url = '/portauthority/setPortAuthorityToSession';
			this.$puiRequests.getRequest(
				url,
				{ portAuthorityId: this.modalData.portAuthority.id },
				(response) => {
					if (response.data) {
						this.app = response.data;
					}
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
			//window.localStorage.setItem('workingPortlocode', this.modalData.port.portlocode);
			//window.localStorage.setItem('workingToken', this.modalData.port.token);
			this.setSessionPort(loginInfo);
			this.setLoginInfo(loginInfo, keepSessionAlive);
		},
		async setSessionPort(loginInfo) {
			console.log(loginInfo);
			var ports = [];
			var portslocodes = [];
			var portstoken = [];
			loginInfo.properties.PORTS.forEach((port) => {
				if (port.portauthorityid == this.modalData.portAuthority.id) {
					ports.push(port.id);
					portslocodes.push(port.portlocode);
					if (port.token) {
						portstoken.push(port.token);
					}
				}
			});

			const url = '/visit/getStatusPA';
			this.$puiRequests.getRequest(
				url,
				{ portAuthorityId: this.modalData.portAuthority.id },
				(response) => {
					if (response.data) {
						window.localStorage.setItem('workingStopStatus', JSON.stringify(response.data[0]));
						window.localStorage.setItem('workingVisitStatus', JSON.stringify(response.data[1]));
					}
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);

			const opts2 = {
				model: 'technicalnauticalservicestatuspa',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'port_authority_id',
							op: 'eq',
							data: this.modalData.portAuthority.id
						}
					]
				}
			};
			let statusAp = [];
			this.$puiRequests.postRequest('/puisearch', opts2, (response) => {
				if (response.data) {
					statusAp = response.data.data.map((sap) => {
						return sap.statusid;
					});
					window.localStorage.setItem('workingTechnicalnauticalservicestatusStatus', statusAp);
				}
			});

			window.localStorage.setItem('workingPortsId', ports);
			window.localStorage.setItem('workingPortsLocodes', portslocodes);
			window.localStorage.setItem('workingPortsToken', portstoken);
		},
		setLoginInfo(loginInfo, keepSessionAlive) {
			this.$store.dispatch('puiLoginAfterLogin', {
				loginInfo: loginInfo,
				keepSessionAlive: keepSessionAlive,
				router: this.$router,
				route: this.$route
			});
		},
		cleanStorage() {
			window.localStorage.removeItem('workingPortAuthorityId');
			window.localStorage.removeItem('workingTechnicalnauticalservicestatusStatus');
			window.localStorage.removeItem('workingPortsId');
			window.localStorage.removeItem('workingPortsLocodes');
			window.localStorage.removeItem('workingPortsToken');
			window.localStorage.removeItem('workingStopStatus');
			window.localStorage.removeItem('workingVisitStatus');
		},
		closeDialog() {
			this.$store.dispatch('puiLoginDoLogout', {});
			return Promise.resolve(true);
		}
	}
};
</script>

<style>
.remove-margin {
	margin: 0% !important;
}
</style>
